/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable eqeqeq */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TextField from '@modules/commons/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LocationIcon from '@src_theme/icons/Location';
import { BLUE_LIGHT } from '@theme_color';
import {
    SECONDARY, GRAY_800, GRAY_500, GRAY_400, GRAY_100,
} from '@src_theme/colors';
import { useTranslation } from '@i18n';
import { getStoreSwitcher } from '@modules/theme/services/graphql';
import {
    setVendorApp,
    setVendor,
    getVendor,
    setVendorCode,
    setVendorCodeApp,
} from '@helper_cookies';
import classNames from 'classnames';
import isMobileApp from '@root/src/helpers/userAgent';

const useStyles = makeStyles(() => ({
    marker: {
        height: 24,
        padding: 2,
    },
    topText: {
        fontSize: 10,
        color: GRAY_500,
        paddingLeft: '0',
        marginBottom: 1,
    },
    caretDown: {
        width: 20,
        height: 20,
        borderRadius: 9999,
        color: GRAY_400,
    },
    selectedOptionLabel: {
        width: '100%',
        maxWidth: 220,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        color: GRAY_800,
        fontSize: 12,
        fontWeight: 500,
        fontFamily: 'inherit',
    },
    container: {
        width: '100%',
        maxWidth: 300,
        height: 40,
        border: 0,
        borderRadius: 9999,
        backgroundColor: GRAY_100,
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        padding: 12,
        cursor: 'pointer',
        '& > svg': {
            color: SECONDARY,
        },
    },
    mainTexts: {
        flex: 1,
    },
    menu: {
        display: 'block',
        overflow: 'hidden',
    },
    wrapperMenu: {
        '& .MuiMenu-paper': {
            width: 300,
        },
    },
    textField: {
        marginBottom: 0,
        padding: '6px 16px',
        position: 'fixed',
        width: 'inherit',
        background: '#fff',
        zIndex: 2,
        height: 'auto',
    },
    itemHighlight: {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    wrapperTextfield: {
        width: 256,
        height: 70,
        marginTop: -9,
    },
    '@media (max-width: 1024px)': {
        container: {
            maxWidth: 180,
        },
        selectedOptionLabel: {
            maxWidth: 90,
        },
    },
    '@media (max-width: 767px)': {
        container: {
            padding: 0,
            width: 'auto',
            height: 28,
            maxWidth: '100%',
            '& > svg': {
                width: 24,
            },
        },
        box: {
            display: 'flex',
            alignItems: 'center',
        },
        boxThird: {
            margin: 0,
        },
        marker: {
            fontSize: 15,
            height: 15,
            marginRight: 2,
        },
        topText: {
            fontSize: 12,
            margin: 0,
            flexShrink: 0,
            '&::after': {
                content: '""',
                whiteSpace: 'pre',
                width: '0.4rem',
                display: 'inline-block',
            },
        },
        selectedOption: {
            fontSize: 10,
            justifyContent: 'start',
            width: 'auto',
            padding: 0,
            color: BLUE_LIGHT,
        },
        selectedOptionLabel: {
            width: 'auto',
            fontWeight: 600,
            overflow: 'hidden',
            maxWidth: '100%',
        },
        mainTexts: {
            display: 'flex',
            width: 'auto',
            overflow: 'hidden',
        },
        caretDown: {
            '& > svg': {
                fontSize: 22,
            },
        },
        wrapperMenu: {
            '& .MuiMenu-paper': {
                width: 'calc(100% - 30px)',
                maxWidth: 767,
            },
        },
    },
}));

const ShoppingAt = ({ className }) => {
    const [getStore, { data, loading, called }] = getStoreSwitcher();
    const { t } = useTranslation(['common']);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedOption, setSelectedOption] = React.useState();
    // const options = data && data.storeSwitcher && data.storeSwitcher.options && data.storeSwitcher.options.length
    //     ? data.storeSwitcher.options.map((option) => ({ label: option.vendor_name, value: option.vendor_id, storeCode: option.store_code }))
    //     : [];

    const options = React.useMemo(() => (data && data.storeSwitcher && data.storeSwitcher.options && data.storeSwitcher.options.length
        ? data.storeSwitcher.options.map((option) => ({ label: option.vendor_name, value: option.vendor_id, storeCode: option.store_code }))
        : []), [data?.storeSwitcher?.options]); // only change `data.storeSwitcher.options` have a changed

    const [tempStore, setTempStore] = React.useState('');
    const [filteredStores, setFilteredStores] = React.useState([]);
    const filterStores = React.useCallback(() => {
        if (!tempStore) return options;
        return options.filter((store) => store?.label.toLowerCase().includes(tempStore.toLowerCase()));
    }, [tempStore, options]);

    React.useEffect(() => {
        setFilteredStores(filterStores());
    }, [filterStores]);

    React.useEffect(() => {
        const getInitialOption = () => {
            const getOption = (id) => options.find((option) => option.value == id);
            if (getVendor()) {
                return getOption(getVendor());
            }
            if (data && data.storeSwitcher && data.storeSwitcher.default_vendor_id) {
                if (isMobileApp === 'app') {
                    setVendorApp(data.storeSwitcher.default_vendor_id);
                    setVendorCodeApp(data.storeSwitcher.default_store_code);
                } else {
                    setVendor(data.storeSwitcher.default_vendor_id);
                    setVendorCode(data.storeSwitcher.default_store_code);
                }
                return getOption(data.storeSwitcher.default_vendor_id);
            }
            return null;
        };
        if (!loading && called) {
            setSelectedOption(getInitialOption());
        }
    }, [loading, called]);

    React.useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                const latitude = position?.coords?.latitude || '';
                const longitude = position?.coords?.longitude || '';
                getStore({
                    variables: {
                        latitude,
                        longitude,
                    },
                });
            }, () => {
                getStore({
                    variables: {
                        latitude: '',
                        longitude: '',
                    },
                });
            });
        } else {
            getStore({
                variables: {
                    latitude: '',
                    longitude: '',
                },
            });
        }
    }, []);

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, option) => {
        setSelectedOption(option);
        if (isMobileApp === 'app') {
            setVendorApp(option.value);
            setVendorCodeApp(option.storeCode);
        } else {
            setVendor(option.value);
            setVendorCode(option.storeCode);
        }
        setAnchorEl(null);
        if (typeof window !== 'undefined') {
            window.location.reload();
        }
    };

    const handleClose = () => {
        setTimeout(() => {
            setAnchorEl(null);
        }, 0);
    };

    return (
        <div className={classNames(classes.container, className)} onClick={handleButtonClick}>
            <LocationIcon width={32} style={{ flexShrink: 0 }} />
            <div className={classes.mainTexts}>
                <div className={classes.topText}>{t('common:shoppingAt:shoppingAt')}</div>
                <ButtonBase className={classes.menu}>
                    <div className={classes.selectedOptionLabel}>{selectedOption ? selectedOption.label : t('common:shoppingAt:selectStore')}</div>
                </ButtonBase>
            </div>
            <ButtonBase className={classes.caretDown}>
                <KeyboardArrowDownIcon />
            </ButtonBase>
            <Menu
                id="shopping-at-menu"
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onEntering={() => {
                    document.querySelector('body').style.padding = 0;
                    document.querySelector('body').style.overflow = 'auto';
                }}
                style={{ maxHeight: '85%' }}
                className={classes.wrapperMenu}
            >
                <div className={classes.wrapperTextfield}>
                    <TextField
                        autoFocus
                        placeholder={t('product:findAStore')}
                        name="name"
                        value={tempStore}
                        onChange={(e) => setTempStore(e.target.value)}
                        className={classes.textField}
                        onKeyDown={(e) => e.stopPropagation()}
                    />
                </div>
                {filteredStores?.map((option, index) => (
                    <MenuItem
                        key={index}
                        onClick={(event) => handleMenuItemClick(event, option)}
                        style={{ fontSize: 12 }}
                        className={option.label === selectedOption.label ? classes.itemHighlight : ''}
                    >
                        <span className={classes.optionLabel}>{option.label}</span>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ShoppingAt;
