import { makeStyles } from '@material-ui/core/styles';
import {
    BLUE_LIGHT,
} from '@theme_color';

const useStyles = makeStyles(() => ({
    container: {
        width: '85px',
        height: '85px',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'fixed',
        bottom: '175px',
        right: '62px',
        border: 'none',
        outline: 'none',
        background: 'none',
        zIndex: 999,
        '@media (max-width: 767px)': {
            bottom: 170,
            right: 20,
        },
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'end',
        padding: '0',
    },
    icon: {
        cursor: 'pointer',
        color: BLUE_LIGHT,
    },
}));

export default useStyles;
